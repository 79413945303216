import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    
    
{/*     
	<meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="A quirky little app that reminds you when it's time to take a small refreshment">
    <meta name="author" content="A Giraffe And A Half">
    <title>Yardarm - Give yourself a break</title> */}
 
<>
		{/* <script>
	   (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
	   (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
	   m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
	   })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
	   ga('create', 'UA-101436099-1', 'auto');
	   ga('send', 'pageview');
	 </script> */}


	

    {/* <!-- favicon Icon --> */}
    {/* <!--<link rel="shortcut icon" href="images/favicon.ico" type="image/x-icon">
	<link rel="icon" href="images/favicon.ico" type="image/x-icon">--> */}
    
     {/* <!-- HTML5 Shim and Respond.js IE8 support of HTML5 elements and media queries --> */}
    {/* <!-- WARNING: Respond.js doesn't work if you view the page via file:// --> */}
    {/* <!--[if lt IE 9]>
        <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
        <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
    <![endif]--> */}

<section id="home" class="top_banner_bg secondary-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="top_banner">

				</div>

				<div class="col-md-6">
					<div class="present">
						<h1>Yardarm: when it’s time to give yourself a treat.</h1>

						<h5> Available on the <span>App Store</span></h5>

						<div class="section_btn">
									<a href="https://itunes.apple.com/us/app/yardarm/id1137570833?ls=1&mt=8"> <button class="btn btn-default" type="submit"> <i class="fa fa-apple" aria-hidden="true"></i> App Store</button> </a>
{/* <!--
								    <span> <a href="#"> <button class="btn btn-default" type="submit"><i class="fa fa-android" aria-hidden="true"></i> Play Store</button> </a> </span>
                  --> */}
						</div>
					</div>
				</div>

				<div class="col-md-6">
					<div class="present_img">
						<img src="images/image-1.png" alt="image" />
					</div>
				</div>

			</div>
		</div>
	</div>
</section>


<section id="features" class="primary-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

			<div class="section_heading">
				<h2> QUIRKY FEATURES </h2>

				<h4>Yardarm offers Beautiful Design &amp; Evocative Sound with lots of small, hidden features</h4>
			</div>

			<div class="col-md-8">
				<div class="features_detail">
					<ul>
						<li>

								<i class="fa fa-picture-o" aria-hidden="true"></i>
								<h5>Colourful Graphics</h5>
								A gently rocking boat on a calm blue ocean, with clouds floating by and interactive bubble machines - plus a hidden pirate ship!
								Aaaaaaar...
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

						</li>

						<li>

								<i class="fa fa-music" aria-hidden="true"></i>
								<h5>Evocative Sound</h5>
								Creaking ropes, waves slapping against the hull and the ring of the ship's bell will transport you to sea.  But listen out for pirates - there's no telling what mood they might be in...
						</li>

						<li>

								<i class="fa fa-comment-o" aria-hidden="true"></i>
								<h5>Words of Wisdom</h5>
								You may be offered a few words of wisdom when you set the timer on your Yardarm.   When is it too early for a snifter but too late for an opener?...Yardarm knows.
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</li>

						<li>

								<i class="fa fa-graduation-cap" aria-hidden="true"></i>
								<h5>Language &amp; Learning</h5>
								How do you say "Cheers" in 20 languages?  What's a good drinking toast to send to your friends?  What did Benjamin Franklin say about patience? Yardarm will tell you.
						</li>
					</ul>
				</div>
			</div>

				<div class="col-md-4">
					<div class="features_img pull-left">
						<img src="images/features_img.png" alt="image" />
					</div>
				</div>

			</div>
		</div>
	</div>
</section>






<section id="testimonials" class="secondary-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="section_heading section_heading_2">
					<h2>Testimonials</h2>
					<h4>Yardarm has received some fabulous reviews:</h4>
				</div>

				<div class="testimonial_slide">
					<div class="testi_detail">
						<div class="testi_img">
							<img src="images/testimonial-1.png" alt="image" />
								<h5>William Bligh</h5>
								<p>Lieutenant</p>
						</div>
						<div class="testi-text">
							<p>Bountiful.  If I’d had Yardarm, I wouldn’t have forgotten the rum ration and those devils might never have mutinied.</p>
						</div>
					</div>
				</div>
				<p>&nbsp;</p>

				<div class="testimonial_slide">
					<div class="testi_detail">
						<div class="testi_img">
							<img src="images/testimonial-2.png" alt="image" />
								<h5>Horatio Nelson</h5>
								<p>Admiral</p>
						</div>
						<div class="testi-text">
							<p>If my crew had Yardarm to keep them disciplined, they might not have sucked me dry...</p>
						</div>
					</div>
				</div>
				<p>&nbsp;</p>

				<div class="testimonial_slide">
					<div class="testi_detail">
						<div class="testi_img">
							<img src="images/testimonial-3.png" alt="image" />
								<h5>Edward Teach</h5>
								<p>Blackbeard</p>
						</div>
						<div class="testi-text">
							<p>HAAAAAaaaard to imagine how we ever managed without it, frankly.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>



<section id="contact" class="primary-bg">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="section_heading">
					<h2>Contact Us</h2>

					<h4>Drop us a line and we will be in touch soon.</h4>
					<h4><i class="fa fa-envelope-o" aria-hidden="true"></i>
								<a href="mailto:info@agiraffeandahalf.com">info@agiraffeandahalf.com</a></h4>
				</div>

				


			</div>
		</div>
	</div>
</section>


</>



    {/* <Link to="/page-2/">Go to ff page 2</Link> */}
  </Layout>
)

export default IndexPage
